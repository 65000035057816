import React from "react"

const Workflow = () => (
    <div className="worflow" id="worflow-des">
        <div className="flow">
            <span className="icon-client-brief"></span>
            <h5>RESEARCH</h5>
            <p className="details">After the quotation has been agreed I will conduct and in-depth research into your project so I know exactly what you want.</p>
        </div>

        <div className="flow">
            <span className="icon-idea-generation"></span>
            <h5>DESIGN CONCEPT</h5>
            <p className="details">After the research stage we begin to design your site through User Experience (UX) and User Interface (UI) web design.</p>
        </div>

        <div className="flow">
            <span className="icon-programming"></span>
            <h5>DEVELOPMENT</h5>
            <p className="details">After the UI has been agreed I will start development and this is where I build the site. I will begin an iterative process until complete.</p>
        </div>
        
        <div className="flow">
            <span className="icon-review-and-approval-cycle"></span>
            <h5>TESTING</h5>
            <p className="details">After the site has been built you will be able to begin UAT. You will be able to view and test the process on my development server.</p>
        </div>
        
        <div className="flow">
            <span className="icon-launch"></span>
            <h5>LAUNCH</h5>
            <p className="details">Once UAT has been completed and signed-off we are ready to launch your site either on my hosted servers or using your own host. Your users will be able to use your new site now.</p>
        </div>
        
        <div className="flow">
            <span className="icon-client-service"></span>
            <h5>RELATIONSHIP</h5>
            <p className="details">After we have gone live this isn't the end. I would like to build a long lasting relationship with my clients and help with any further upgrades or new sites.</p>        
        </div>
        
    </div>
)
  

export default Workflow