import React from "react"
import { Link } from "gatsby"
import { Waypoint } from 'react-waypoint';

import Chris5 from '../../images/chris/chris5.jpg'
import Chris6 from '../../images/chris/chris6.jpg'
import HKStreet from '../../images/hk-street.jpg'
import RubyPHP from '../../images/RubyPHP.jpg'
import htmlcss from '../../images/htmlcss.jpg'
import {handleWaypointIn} from "../../utilities/waypoint"



const AboutMe = () => (
    <>

    <div className="columns-2 image-right" id="about-top">
        <div className="column-1">
            <div className="column-content left">
                <Waypoint onEnter={() => handleWaypointIn('about-me-text')}>         
                    <div id="about-me-text"> 
                        <h2>About Me</h2>
                        <p>Hi, my name is Chris Bartlett and I am a Full-Stack Web Developer with over 20 years corporate/agency experience in both Frontend & Backend web development</p>
                        <p>I like building websites. I always have. I started teaching myself HTML after I grduated with a degree in Politics & Government from the University of Essex way back in 1997. </p>
                        <p>In 1998 I got my first job as HTML editor for the ir group in London. They were a small investor relations agency who specialised in creating finacial websites for companies. I used to recreate 100 pages of financial reports by handcoding HTML tables - so as you can imagine I got quite good at HMTL!</p>              
                    </div>    
                </Waypoint>
            </div>
        </div>
        <div className="column-2 image-wrapper">
            <div className="mobile-about-top"/>          
            <img src={Chris6} alt="Chris Bartlett Full-Stack Web Developer" id="c6" loading="lazy" />
        </div>
    </div>

    <div className="columns-2 image-left">
        <div className="column-1 image-wrapper">
            <img src={htmlcss} alt="Chris Bartlett Full-Stack Web Developer" id="c1" loading="lazy" />
        </div>
        <div className="column-2">
            <div className="column-content right">
                <Waypoint onEnter={() => handleWaypointIn('risk-text')}>         
                    <div id="risk-text">           
                        <h2>Risk Publications</h2>              
                        <p>In Decemeber 1999, just before the Y2K bug that never happened, I moved to financial publishing and conference company - Risk Publications as a Web Producer. I worked on a custom financial search engine, creating HTML static pages for the article side of the search engine. There were 3 of us at the start and we launched the company's first corporate site. From there we started launching all company's publications websites.</p>
                        <p>The team grew and I later became team leader to a team of 12 web developers. I became WEBMASTER lol and then later Senior Producer (much better!). I helped advise the company's first custom built PHP CMS and started recreating the static sites in the CMS. We started learning CSS as it had just come out. We built hundress of small sites and email newsletters.</p>
                        <p>The team was comprised of frontend developers so I started to learn PHP to help build modules for the CMS. Over the years I became increasingly drawn towards backend web development coding in PHP and SQL for MySQL.</p>   
                    </div>    
                </Waypoint>    
            </div>
        </div>
    </div>
        
    <div className="columns-2 image-right">
        <div className="column-1">
            <div className="column-content left">
                <Waypoint onEnter={() => handleWaypointIn('incisive-text')}>         
                    <div id="incisive-text">  
                        <h2 >Incisive Media</h2>
                
                        <p>After nurmerous acquisitions we grew in size to about 20 so we split the team and I moved over the backend team. We started building a new PHP CMS but was then aquired by Incisve Media and who decided to outsource the work and chaned to a Ruby on Rails CMS. I then learnt Ruby on Rails and built many small internal systems, such as the IT helpdesk, in Ruby on Rails.</p>
                        <p>I also looked after the a medium size Magento 1.9 bookstore and started the fun process of upgrading to Magento 2.0, which was halted after I found a bug with Magento 2.0 core group products.</p>
                        <p>I used Jenkins, gulp and git to create a workflow for the frontend team to publish to the dev, staging and live servers. </p>          
                    </div>    
                </Waypoint>        
            </div>
        </div>
        <div className="column-2 image-wrapper">
            <img src={RubyPHP} alt="Chris Bartlett Full-Stack Web Developer" id="c3" loading="lazy" />
        </div>
    </div>
    
    

    <div className="columns-2 image-left">
        <div className="column-1 image-wrapper">
            <img src={HKStreet} alt="Chris Bartlett Full-Stack Web Developer" id="c4" loading="lazy" />
        </div>
        <div className="column-2">
            <div className="column-content right">
                <Waypoint onEnter={() => handleWaypointIn('hk-text')}>         
                    <div id="hk-text">       
                        <h2>Hong Kong</h2>
                        <p>Fancing a change in 2011 I transfered to the Hong Kong office and have lived here ever since.</p>
                        <p>In 2017, after 18 years at Incisive Media, I left and joined Wecreate Advertising Agency as a web developer. Shortly after I became Lead Developer. At Wecreate I built and worked on a huge amount of client websites many of which you can see in my <Link to="portfolio">portfolio</Link> section.</p>
                    </div>    
                </Waypoint>               
            </div>
        </div>
    </div>

    <div className="columns-2 image-right">
        <div className="column-1">
            <div className="column-content left">
                <Waypoint onEnter={() => handleWaypointIn('tldr-text')}>         
                    <div id="tldr-text">           
                        <h2>TL;DR</h2>
                        <ul>
                            <li>Building bespoke web sites and business applications in PHP, Javascript and Ruby.</li>
                            <li>Work well with designers, project managers and other developers, within tight deadlines.</li>
                            <li>Full software development life-cycle from initial client requirements, analysis, through to implementation and deployment.</li>
                            <li>Happy to lead a team, work within one or alone on projects.</li>
                            <li>Always willing to learn new technologies.</li>
                            <li>Creates pixel perfect websites</li>
                            <li>Able to communicate complex ideas to C-level and different stakeholders</li>
                            <li>An active developer with managerial skills</li>
                            <li>Native English Speaker</li>
                            <li>Willing to change country if sponsored</li>
                            <li>Open to remote work</li>
                            <li>UK citizien</li>
                            <li>Permanent resident in Hong Kong</li>
                            <li>At the moment I have a full-time job so can only work in my spare time.</li>
                            <li>Feel free to <Link to="#get-in-touch">Get in touch</Link>.</li>
                        </ul>                               
                    </div>    
                </Waypoint>               
            </div>
        </div>
        <div className="column-2 image-wrapper">
            <div className="mobile-about-top"/>  
            <img src={Chris5} alt="Chris Bartlett Full-Stack Web Developer" id="c5" loading="lazy" />
        </div>
    </div>    

    </>
)

export default AboutMe