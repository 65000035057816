import React from "react"
import { Link } from "gatsby"
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../utilities/waypoint"
import Layout from "../components/layout/Layout"
import TechStack from "../components/tech/TechStack"
import AboutMe from "../components/about/AboutMe"
import ContactSection from "../components/contact/ContactSection"
import Workflow from "../components/about/Workflow"
import WorkflowDev from "../components/about/WorkflowDev"
import SEO from "../components/seo"



const About = () => {

  const showWorkflow = (e, div, button) => {
    e.preventDefault()
    const tab = document.querySelector(div)
    const buttonSelected = document.querySelector(button)

    if (tab.classList.contains("active") === false) {
        Array.from(document.querySelectorAll(".worflow")).forEach(function(allTab) {
            allTab.classList.remove("active")
        })
        Array.from(document.querySelectorAll(".process-buttons button")).forEach(function(buttonTab) {
            buttonTab.classList.remove("active")
        })        
        tab.classList.add("active")
        buttonSelected.classList.add("active")
    } 
  }
  return (
    <div className="about">
      <Layout>
        <SEO title="About Me" />
        
          <section className="about-me">
            <div className="about-me-wrapper">
              <AboutMe />    
            </div>           
          </section>   

          <section className="about-tldr">
            <div className="about-tldr-wrapper">
                <Waypoint onEnter={() => handleWaypointIn('current-text')}>         
                    <div id="current-text">  
                        <h2>Current Job Role</h2>
                        <p>At Wecreate I'm actually doing 3 jobs in one:</p>
                        <ol>
                            <li>I manage a team of developers. We started with 3 of us, went up to 8 at one point and back down to 4 at the moment. 
                                <ul>
                                    <li>Perform weekly team meetings updating the team about events and any going through any problem the team might have and how to help fix them - be it technical or workflow related</li>
                                    <li>Perform weekly one on one meetings with the team and the PMs to manage their workflow and project updates</li>
                                    <li>Help the team out whenever they have problems and mentor junior memebers</li>
                                    <li>I sit in on dev meetings with PMs to help their project run smoothly and advise on any issues.</li>
                                    <li>Hold performance reviews for the devs</li>
                                    <li>Provide leadership in the development and implementation of Wecreates digital roadmap</li>
                                    <li>Ensure projects progress smoothly through the software development life cycle</li>
                                    <li>Participate in requirements gathering, application design, planning and review.</li>
                                    <li>Acts as a bridge between the development team and Project Management and Design and Creative Director</li>
                                    <li>Help with estimating development work for quotations</li>
                                </ul>
                            </li>
                            <li>I'm the sysadmin. Well I'm not really a sysAdmin but I look after the company's servers:
                                <ul>
                                    <li>I setup all servers and client servers through AWS and Google Cloud.</li>
                                    <li>I setup a Gitlab server to allow the dev team to have a private repo.</li>
                                    <li>I setup plesk on our dev and client server, along with company GSuite email</li>
                                    <li>I move sites from dev to live or clients servers</li>
                                    <li>I fix any problems we have on the servers, apache, nginx, php, etc</li>
                                </ul>
                            </li>
                            <li>
                                I'm also a full time web developer. 
                                <ul>
                                    <li>I've worked on at least 50 websites in the last 4 years</li>
                                    <li>I've worked on mainly Wordpress-based websites, but also Drupal, Laravel, Gatsby, nodejs and static HTML websites.</li>
                                    <li>I've created about 20 websites completely by myself, and co-worked on about another 10 websites and fixed, added functionality or finished about another 20 sites.</li>
                                    <li>Learnt React and Gatsby during this time.</li>
                                </ul>
                                
                            </li>
                        </ol>     

                        <p>If you are intersted in hiring me for freelance work please <Link to="#get-in-touch">Get in touch</Link>.</p>   
                    </div>    
                </Waypoint>     
            </div>           
          </section>   

          


          <section className="process">
            <Waypoint onEnter={() => handleWaypointIn('process-text')}>         
                <div id="process-text">            
                    <h2>MY WEB DESIGN & DEVELOPMENT PROCESS</h2> 

                    <div className="process-buttons-wrapper">
                        <p className="intro">I use a similar process for all my projects, depending on whether you need Web Development only or Web Design as well there is an extra step:</p>
                        <div className="process-buttons">
                        

                            <button className="web-dev-button active" onClick={(e) => { showWorkflow(e, "#worflow-dev", ".web-dev-button") }}>
                                <div className="btn-skew">Web Development</div>
                            </button>
                            <button className="web-des-button btn-spread-thin" onClick={(e) => { showWorkflow(e, "#worflow-des", ".web-des-button") }}>
                                <div className='insider'></div>
                                Web Design & Development
                            </button>
                        </div>
                    </div>

                </div>    
            </Waypoint> 
            
            <WorkflowDev />
            <Workflow />
          </section>             

          
          <TechStack />

          <ContactSection/>
                            
      </Layout> 
  </div>
  )
}

export default About