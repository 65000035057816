import React, { useState, useEffect }  from "react"
import { StaticQuery } from 'gatsby'
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../../utilities/waypoint"


const TechStack = () => {
    // store the isotope object in one state
    const [isotope, setIsotope] = useState(null);
    // store the filter keyword in another state
    const [filterKey, setFilterKey] = useState("*");  

    // setup intial isotop 
    useEffect(() => {
        if (typeof window !== `undefined`) {
            const Isotope = require("isotope-layout/js/isotope")
            setIsotope(
                new Isotope(".filter-container", {
                    itemSelector: ".grid-item",
                    layoutMode: 'fitRows',
                })
            )
        }
    }, [])
  
    useEffect(() => {
        if (isotope) {
            filterKey === "*"
            ? isotope.arrange({ filter: `*` })
            : isotope.arrange({ filter: `.${filterKey}` })
        }
    }, [isotope, filterKey])


    const change = (e) => {
        setFilterKey(e.target.value)
    }

    const setFilterLink = (e, filter) => {
        e.preventDefault()
        setFilterKey(filter)
    }    

    return ( 
        <StaticQuery
        query={graphql `
        query {
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___title]}, filter: {frontmatter: {post_type: {eq: "tech"}}}) {
          edges {
            node {
              fields {
                slug
              }
              html
              frontmatter {
                  title
                  post_type   
                  filters       
                  svg
              }
            }
          }
        }
      }
      `}
        render={data => {
            let techs = data.allMarkdownRemark.edges

            return (
            <>
                <section className="tech-stack-header">
                    <Waypoint onEnter={() => handleWaypointIn('tech-text')}>         
                        <div id="tech-text">    
                            <h2>Tech I use</h2> 
                            <div className="tech-filters-desktop">
                                <button className={`${filterKey === '*'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, '*')}>All</button>
                                <button className={`${filterKey === 'language'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'language')}>Languages</button>
                                <button className={`${filterKey === 'cms'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'cms')}>CMS</button>
                                <button className={`${filterKey === 'framework'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'framework')}>Framework / Library</button>
                                <button className={`${filterKey === 'hosting'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'hosting')}>Hosting</button>
                                <button className={`${filterKey === 'other'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'other')}>Other</button>
                            </div> 
                        </div>    
                    </Waypoint> 
                    <div className="tech-filters-mobile">
                        <span className="select-wrapper">
                            <select name="service-filter" className="service-filter" onChange={(e) => change(e)} value={filterKey}>
                                <option value="*">Select Tech Stack..</option>
                                {/*  
                                    Below is the values of the filter. Add one option for each filter. Values are taken from 
                                    the markdown file for services in this format:
                                    filters: "test chart"
                                    Add a space between each filter value in the markdown file                        
                                */}
                                <option value="language">Language</option>
                                <option value="cms">CMS</option>
                                <option value="framework">Framework / Library</option>
                                <option value="hosting">Hosting</option>
                                <option value="other">Other</option>
                            </select> 
                        </span> 
                    </div>                    

                </section>    
                
                <section id="tech-stack" className="filter-container">
                    <div className="grid">
                    {                  
                        techs.map((tech, index) => {
                        return (
                            <div key={index} className={`grid-item ${tech.node.frontmatter.filters} ${filterKey !== '*'  ? "active" : null}`}>
                                    <div className="tech-content">
                                        <div className="tech-upper">
                                            <div className="tech-left">
                                                <h5>{tech.node.frontmatter.title}</h5>
                                            </div>
                                            <div className="tech-right">
                                                <div dangerouslySetInnerHTML = {{__html: tech.node.frontmatter.svg }}/> 
                                            </div> 
                                        </div>
                                        <div className="tech-lower" dangerouslySetInnerHTML={{ __html: tech.node.html }} />

                                    </div>
                            </div>
                        )})            
                        
                    }
                    </div>
                </section>
                            
            </>
        )}}
      />
    )
}
  

export default TechStack